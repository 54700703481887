.form {
  margin-bottom: 2rem;
}

.wrapper {
  margin-bottom: 1rem;
}

.input {
  border: 1px solid #eee;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 0.4rem;
  background: #eee;
  transition: 0.2s;
}

.input:hover,
.input:focus {
  outline: none;
  border-color: #333;
  background: white;
  box-shadow: 0 0 0 1px #dddddd;
}

.label {
  display: block;
  font-size: 1rem;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.perdeu {
  display: inline-block;
  color: #665;
  padding: 0.5rem 0;
  line-height: 1;
}

.perdeu::after {
  content: '';
  height: 2px;
  width: 100%;
  background: currentColor;
  display: block;
}
