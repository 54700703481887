.button {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #00a394;
  color: #333;
  min-width: 8rem;
  padding: 0.8rem 1.2rem;
  box-sizing: border-box;
  transition: 0.1s;
  margin-bottom: 2rem;
}

.button:hover,
.button:focus {
  outline: none;
  box-shadow: 0 0 0 3px #00a394, 0 0 0 4px #00a394;
  color: #fff;
}

.button:disabled {
  opacity: 0.5;
  cursor: wait;
}
