.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 100;
  background: white;
  top: 0;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
}

.logo {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.transitar {
  margin-right: 0.5rem;
}

.login {
  color: #333;
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.login::after {
  content: '';
  display: inline-block;
  width: 14px;
  height: 17px;
  background: url('../Assets/user.svg') no-repeat center center;
  margin-left: 0.5rem;
  position: relative;
  top: -1px;
}
