@import url(https://fonts.googleapis.com/css2?family=Spectral:wght@700&display=swap);
* {
  box-sizing: border-box;
}

body {
  padding-top: 4rem;
  margin: 0px;
  color: #333;
  --type-first: Helvetica, Arial, sans-serif;
  --type-second: 'Spectral', georgia;
  font-family: var(--type-first);
}

::-webkit-scrollbar {
  display: none;
}

h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

img {
  display: block;
  max-width: 100%;
}

button,
input {
  display: block;
  font-size: 1rem;
  font-family: var(--type-first);
  color: #333;
}

a {
  text-decoration: none;
  color: #333;
}

.container {
  max-width: 50rem;
  padding: 0 1rem;
  margin: 0 auto;
}

.mainContainer {
  margin-top: 2rem;
}

.title {
  font-family: var(--type-second);
  line-height: 1;
  font-size: 3rem;
  margin: 1rem 0;
  position: relative;
  z-index: 1;
  padding: 2rem 0 2rem 0;
}

.title::after {
  content: '';
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background: #00a394;
  position: absolute;
  bottom: 2rem;
  left: -5px;
  border-radius: 0.2rem;
  z-index: -1;
}

.animeLeft {
  opacity: 0;
  transform: translateX(-20px);
  -webkit-animation: animeLeft 0.3s forwards;
          animation: animeLeft 0.3s forwards;
}

@-webkit-keyframes animeLeft {
  to {
    opacity: 1;
    transform: none;
    transform: initial;
  }
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: none;
    transform: initial;
  }
}

.App {
  display: flex;
  flex-direction: column;
  min-height: calc(88vh + 8rem);
}

.AppBody {
  flex: 1 1;
}

.loading {
  display: flex;
  margin: 20rem auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 10px solid #ccc;
  border-right-color: transparent;
  -webkit-animation: loading 1s infinite;
          animation: loading 1s infinite;
}

@-webkit-keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

.Footer_footer__T7yIn {
  background: #00a394;
  padding: 0.5rem 0 0 0;
  height: 8rem;
  text-align: center;
}

.Header_header__1Fnll {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 100;
  background: white;
  top: 0;
}

.Header_nav__16EUy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
}

.Header_logo__2i62G {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.Header_transitar__2NKjC {
  margin-right: 0.5rem;
}

.Header_login__V5AKa {
  color: #333;
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.Header_login__V5AKa::after {
  content: '';
  display: inline-block;
  width: 14px;
  height: 17px;
  background: url(/static/media/user.cd5f6141.svg) no-repeat center center;
  margin-left: 0.5rem;
  position: relative;
  top: -1px;
}

.Button_button__2iv9r {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #00a394;
  color: #333;
  min-width: 8rem;
  padding: 0.8rem 1.2rem;
  box-sizing: border-box;
  transition: 0.1s;
  margin-bottom: 2rem;
}

.Button_button__2iv9r:hover,
.Button_button__2iv9r:focus {
  outline: none;
  box-shadow: 0 0 0 3px #00a394, 0 0 0 4px #00a394;
  color: #fff;
}

.Button_button__2iv9r:disabled {
  opacity: 0.5;
  cursor: wait;
}

.Home_home__1tl71 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;
  grid-gap: 2rem;
  gap: 2rem;
}

.Home_home__1tl71::before {
  display: block;
  content: '';
  background: url(/static/media/photo.cff0373e.jpg) no-repeat center;
  background-size: cover;
}

.Home_info__2ISgW {
  max-width: 30rem;
  padding: 1rem;
  margin-top: 20vh;
}

.Home_info__2ISgW p {
  font-size: 1.3rem;
  color: #828282;
  margin-bottom: 2rem;
}

@media (max-width: 40rem) {
  .Home_home__1tl71 {
    grid-template-columns: 1fr;
  }
  .Home_home__1tl71::before {
    display: none;
  }
  .Home_info__2ISgW {
    max-width: 100%;
  }
}

.Input_wrapper__2GIuc {
  margin-bottom: 1rem;
}

.Input_input__mf4d7 {
  border: 1px solid #eee;
  display: block;
  width: 23.5rem;
  height: 50px;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 0.4rem;
  background: #eee;
  transition: 0.2s;
}

.Input_input__mf4d7:hover,
.Input_input__mf4d7:focus {
  outline: none;
  border-color: #333;
  background: white;
  box-shadow: 0 0 0 1px #dddddd;
}

.Input_label__1ogNm {
  display: block;
  font-size: 1rem;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.Input_error__21MUy {
  color: #f31;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

@media (max-width: 40rem) {
  .Input_input__mf4d7 {
    width: 100%;
  }
}

.LoginForm_form__352rg {
  margin-bottom: 2rem;
}

.LoginForm_wrapper__1NhSB {
  margin-bottom: 1rem;
}

.LoginForm_input__mTxl1 {
  border: 1px solid #eee;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 0.4rem;
  background: #eee;
  transition: 0.2s;
}

.LoginForm_input__mTxl1:hover,
.LoginForm_input__mTxl1:focus {
  outline: none;
  border-color: #333;
  background: white;
  box-shadow: 0 0 0 1px #dddddd;
}

.LoginForm_label__GzoCm {
  display: block;
  font-size: 1rem;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.LoginForm_perdeu__Kramt {
  display: inline-block;
  color: #665;
  padding: 0.5rem 0;
  line-height: 1;
}

.LoginForm_perdeu__Kramt::after {
  content: '';
  height: 2px;
  width: 100%;
  background: currentColor;
  display: block;
}

.Login_login__2disq {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;
  grid-gap: 2rem;
  gap: 2rem;
}

.Login_login__2disq::before {
  display: block;
  content: '';
  background: url(/static/media/photo.cff0373e.jpg) no-repeat center;
  background-size: cover;
}

.Login_forms__BpHWv {
  max-width: 30rem;
  padding: 1rem;
  margin-top: 20vh;
}

@media (max-width: 40rem) {
  .Login_login__2disq {
    grid-template-columns: 1fr;
  }
  .Login_login__2disq::before {
    display: none;
  }
  .Login_forms__BpHWv {
    max-width: 100%;
  }
}

.ButtonRequest_link__NHWT9 {
  height: 180px;
  width: 180px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border-radius: 0.4rem;
  background: white;
  border: 1px solid #707070;
  color: #333;
  min-width: 8rem;
  padding: 0.8rem 1.2rem;
  box-sizing: border-box;
  transition: 0.1s;
}

.ButtonRequest_link__NHWT9:hover,
.ButtonRequest_link__NHWT9:focus {
  outline: none;
  border: 1px solid #00a394;
  background: #00a394;
  box-shadow: 0 0 0 2px #00a394;
}

.ButtonRequest_link__NHWT9:disabled {
  opacity: 0.5;
  cursor: wait;
}

@media (max-width: 25rem) {
  .ButtonRequest_link__NHWT9 {
    width: 135px;
  }
}

@media (min-width: 26rem) and (max-width: 48rem) {
  .ButtonRequest_link__NHWT9 {
    width: 160px;
  }
}

.User_header__y8Z5K {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
}

.User_grid__25GH3 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 1rem;
  justify-items: center;
}

@media (max-width: 40rem) {
  .User_grid__25GH3 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.User_icon__1DoXs {
  display: block;
  margin: 0 auto;
  height: 100px;
  top: 50%;
}

.User_disabled__19-q0 {
  display: none;
}

.Requests_linha__3b-2t {
  background-color: #eee;
}

.Requests_wrapper__21OCU {
  margin-bottom: 1rem;
}

.Requests_flex__3ZFJV {
  display: flex;
  justify-content: space-between;
}

.Requests_input__2PFmh,
.Requests_select__3k2OE {
  border: 1px solid #eee;
  display: block;
  width: 23.5rem;
  height: 50px;
  font-size: 1rem;
  font-family: var(--type-first);
  color: #333;
  padding: 0.8rem;
  border-radius: 0.4rem;
  background: #eee;
  transition: 0.2s;
}

.Requests_input__2PFmh:hover,
.Requests_input__2PFmh:focus,
.Requests_select__3k2OE:hover,
.Requests_select__3k2OE:focus {
  outline: none;
  border-color: #333;
  background: white;
  box-shadow: 0 0 0 1px #dddddd;
}

.Requests_label__RJeaM {
  display: block;
  font-size: 1rem;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.Requests_disabled__3QUWE {
  display: none;
}

.Requests_delete__3YPc7 {
  background: #ddd;
  padding: 0.3rem 0.6rem;
  line-height: 1;
  border: 1px solid transparent;
  font-size: 0.875rem;
  font-family: var(--type-first);
  cursor: pointer;
  border-radius: 0.4rem;
  transition: 0.1s;
}

.Requests_delete__3YPc7:focus,
.Requests_delete__3YPc7:hover {
  outline: none;
  background: white;
  box-shadow: 0 0 0 3px #eee;
  border-color: #333;
}

@media (max-width: 40rem) {
  .Requests_flex__3ZFJV {
    display: block;
    justify-content: center;
  }

  .Requests_input__2PFmh {
    width: 100%;
  }
}

.Modal_modal__25Kbl {
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 10px;
  border: 3px solid #00a394;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 2rem;
  z-index: 1000;
}

.Modal_overlay__mzaiv {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.Modal_flex__F98Y5 {
  display: flex;
  justify-content: space-evenly;
  margin: 1rem 1rem -1rem 1rem;
}

.Modal_invisible__3fj4l {
  display: inline-block;
  margin: 1rem;
}

