.link {
  height: 180px;
  width: 180px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border-radius: 0.4rem;
  background: white;
  border: 1px solid #707070;
  color: #333;
  min-width: 8rem;
  padding: 0.8rem 1.2rem;
  box-sizing: border-box;
  transition: 0.1s;
}

.link:hover,
.link:focus {
  outline: none;
  border: 1px solid #00a394;
  background: #00a394;
  box-shadow: 0 0 0 2px #00a394;
}

.link:disabled {
  opacity: 0.5;
  cursor: wait;
}

@media (max-width: 25rem) {
  .link {
    width: 135px;
  }
}

@media (min-width: 26rem) and (max-width: 48rem) {
  .link {
    width: 160px;
  }
}
