.home {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;
  gap: 2rem;
}

.home::before {
  display: block;
  content: '';
  background: url(../Assets/photo.jpg) no-repeat center;
  background-size: cover;
}

.info {
  max-width: 30rem;
  padding: 1rem;
  margin-top: 20vh;
}

.info p {
  font-size: 1.3rem;
  color: #828282;
  margin-bottom: 2rem;
}

@media (max-width: 40rem) {
  .home {
    grid-template-columns: 1fr;
  }
  .home::before {
    display: none;
  }
  .info {
    max-width: 100%;
  }
}
