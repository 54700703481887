.linha {
  background-color: #eee;
}

.wrapper {
  margin-bottom: 1rem;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.input,
.select {
  border: 1px solid #eee;
  display: block;
  width: 23.5rem;
  height: 50px;
  font-size: 1rem;
  font-family: var(--type-first);
  color: #333;
  padding: 0.8rem;
  border-radius: 0.4rem;
  background: #eee;
  transition: 0.2s;
}

.input:hover,
.input:focus,
.select:hover,
.select:focus {
  outline: none;
  border-color: #333;
  background: white;
  box-shadow: 0 0 0 1px #dddddd;
}

.label {
  display: block;
  font-size: 1rem;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.disabled {
  display: none;
}

.delete {
  background: #ddd;
  padding: 0.3rem 0.6rem;
  line-height: 1;
  border: 1px solid transparent;
  font-size: 0.875rem;
  font-family: var(--type-first);
  cursor: pointer;
  border-radius: 0.4rem;
  transition: 0.1s;
}

.delete:focus,
.delete:hover {
  outline: none;
  background: white;
  box-shadow: 0 0 0 3px #eee;
  border-color: #333;
}

@media (max-width: 40rem) {
  .flex {
    display: block;
    justify-content: center;
  }

  .input {
    width: 100%;
  }
}
