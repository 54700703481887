.header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
  justify-items: center;
}

@media (max-width: 40rem) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.icon {
  display: block;
  margin: 0 auto;
  height: 100px;
  top: 50%;
}

.disabled {
  display: none;
}
