.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 10px;
  border: 3px solid #00a394;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 2rem;
  z-index: 1000;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.flex {
  display: flex;
  justify-content: space-evenly;
  margin: 1rem 1rem -1rem 1rem;
}

.invisible {
  display: inline-block;
  margin: 1rem;
}
